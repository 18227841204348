body {
  font-family: 'Fira Sans', sans-serif;
  margin: 0;
}

section {
  background-color: white;
}

.hero {
  background-image: url("./assets/DenverSkyline.png");
  background-size: cover;
  background-color: #1c1d25;
  height: 100vh;
  color: white;
  font-size: 40px;
}

footer {
  background-color: black;
  color: white;
  text-align: center;
  padding: 80px;
}

nav {
  display: flex;
  justify-content: space-between;
}

ul {
  display: flex;
  list-style-type: none;
  width: 500px;
  justify-content: space-around;
  font-size: 30px;
}

li {
  text-decoration: none;
  cursor: pointer;
}

h1 {
  font-size: 90px;
  margin: 0;
}

h2 {
  font-size: 50px;
}

p {
  margin: 0;
  font-size: 30px;
  font-weight: 300;
}

.subtext {
  font-size: 15px;
  font-weight: 300;
}

.hero-area {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.hero-text {
  margin-left: 80px;
  margin-bottom: 200px;
}

.button {
  width: 200px;
  height: 45px;
  border-radius: 30px;
  background-color: coral;
  text-align: center;
  font-size: 30px;
  font-weight: 300;
  padding-top: 5px;
  margin-top: 20px;
}

.button:hover {
  background-color: #ef744b;
}

.button:active {
  background-color: #A9A9A9;
  color: #808080;
}

.socials {
  padding-bottom: 200px;
}

.logo {
  height: 80px;
  margin: 30px;
}
.social {
  margin: 20px;
}

.sub-section {
  display: flex;
  justify-content: space-around;
  padding: 80px;
}

.sub-section-alternative {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 80px;
}

.headshot {
  width: 300px;
  border-radius: 150px;
  margin-left: 80px;
}

.headshot-container {
  display: flex;
  align-items: center;
}

.project-card {
  width: 300px;
  height: 500px;
  box-shadow: 5px 5px 20px rgb(197, 195, 195);
  margin: 10px;
}

.project-container {
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 1000px) {
  .project-container {
      display: flex;
      flex-wrap: wrap;
  }
}

.project-image {
  margin-top: 25px;
  width: 250px;
  border-radius: 125px;
}

hr {
  margin-left: 20px;
  margin-right: 20px;
}

.project-link {
  text-decoration: none;
  color: #ef744b;
}

a {
  text-decoration: none;
  color: white;
}

.hamburger {
  display: none;
}

.hamburger:focus{
  outline: 0;
}


@media only screen and (max-width: 1000px) {
  .hamburger {
      display: block;
      border: 0;
      background-color: transparent;
      color: white;
      font-size: 30px;
      margin: 20px;
      align-self: flex-end;
  }

  ul {
      display: none;
      background-color: #2b2c38;
      margin: 0ppx;
  }

  ul.show {
      display: block;
  }

  nav {
      display: flex;
      flex-direction: column-reverse;
      background-color: #2b2c38;
  }

  .logo {
      display: none;
  }
}